/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react'
import { Link } from 'gatsby'
import Banner from './artwork/abr-014.js'
import Artwork from './artwork'
import './layout.css'

const Layout = ({ children }) => (
  <main>
    <header className="flex justify-center w-full py-6 mx-auto max-w-screen-sm page-header">
      <Link to="/" className="w-full banner"><Banner /></Link>
    </header>
    {children}
    <footer className="flex justify-around mx-auto my-16 max-w-screen">
      <Artwork type="oil" />
      <Artwork type="racing" />
      <Artwork type="skull" />
    </footer>
  </main>
)

export default Layout
