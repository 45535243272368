import React from 'react'

import HandleBars from './abr-001'
import Crest from './abr-002'
import Shield from './abr-003'
import Flag from './abr-004'
import Wheel from './abr-006'
import Jersey from './abr-007'
import RacingTeam from './abr-008'
import Fist from './abr-010'
import Oil from './abr-012'
import Skull from './abr-013'
import Banner from './abr-014'
import Bmx from './bmx'
import Mtb from './mtb'
import Velo from './velo'

const Container = ({ children }) => (
  <div className="badge">
    {children}
  </div>
)

const Artwork = ({ type, theme }) => {
  switch (type) {
    case 'abr_01':
    case 'handlebars':
      return (
        <Container>
          <HandleBars />
        </Container>
      )
    case 'abr_02':
    case 'crest':
      return (
        <Container>
          <Crest />
        </Container>
      )
    case 'abr_03':
    case 'shield':
      return (
        <Container>
          <Shield />
        </Container>
      )
    case 'abr_04':
    case 'flag':
      return (
        <Container>
          <Flag />
        </Container>
      )
    case 'abr_06':
    case 'wheel':
      return (
        <Container>
          <Wheel />
        </Container>
      )
    case 'abr_07':
    case 'jersey':
      return (
        <Container>
          <Jersey />
        </Container>
      )
    case 'abr_08':
    case 'racing':
      return (
        <Container>
          <RacingTeam />
        </Container>
      )
    case 'abr_10':
    case 'fist':
      return (
        <Container>
          <Fist />
        </Container>
      )
    case 'abr_12':
    case 'oil':
      return (
        <Container>
          <Oil />
        </Container>
      )
    case 'abr_13':
    case 'skull':
      return (
        <Container>
          <Skull />
        </Container>
      )
    case 'abr_14':
    case 'banner':
      return (
        <Container>
          <Banner />
        </Container>
      )
    case 'bmx':
      return (
        <Container>
          <Bmx primary="#f47b2d" accent="#29803f" blank="#fff" />
        </Container>
      )
    case 'mtb':
      return (
        <Container>
          <Mtb primary="#f47b2d" accent="#29803f" blank="#fff" />
        </Container>
      )
    case 'velo':
      return (
        <Container>
          <Velo primary="#f47b2d" accent="#29803f" blank="#fff" />
        </Container>
      )
    default:
      return null
  }
}

export default Artwork
